@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Aclonica&family=Annie+Use+Your+Telescope&family=Capriola&family=Yeon+Sung&display=swap);
body {
  margin: 0;
 font-family: 'Merriweather', serif!important;

 
}

main{
  background: #fafafa;
}
@media only screen and (max-width: 600px) {
  .RecipeTittle {
    font-size: 18px!important;
  }
  .slide_button a{
    padding: 5px 10px!important;
    font-size: 12px!important;
    margin: 0px 0 0!important;
  }
 
}
@media (max-width: 1025px) {
  .sliderimage {
    height:auto!important;
  }
}
.slick-dots li button:before{
  font-size: 17px!important;
}
.ui.label{
  /* color: #feaa26!important; */
  background-color: transparent!important;
}
.ui.multiple.search.dropdown:hover{
  border: 1px solid #363940;
}
.coolHead {
  text-align: center;
  font-family: 'Oswald', Helvetica, sans-serif;
  font-size: 80px;
  transform: skewY(-10deg);
  letter-spacing: 4px;
  word-spacing: -8px;
  color: tomato;
  text-shadow: 
    -1px -1px 0 firebrick,
    -2px -2px 0 firebrick,
    -3px -3px 0 firebrick,
    -4px -4px 0 firebrick,
    -5px -5px 0 firebrick,
    -6px -6px 0 firebrick,
    -7px -7px 0 firebrick,
    -8px -8px 0 firebrick,
    -30px 20px 40px dimgrey
}
.breadcrumb{
  font-size:15px!important;
  font-weight:600 ;
}






:root {
  --primary: #1888ff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: #1888ff;
  background-color: var(--primary);
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid #1888ff;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
 
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  border-top: 1px solid #f0f0f0;
    background: #fafafa;
    font-weight: 500;
    /* font-size: 13px; */
    border-bottom: 1px solid #f0f0f0 ;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  /* width: 70vw; */
  justify-content: end;
  margin-right: 2rem;
  letter-spacing: .5px;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: #363940;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #fafafa;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  color: #818592;
}

.fa-bars {
  color:  #363940;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    /* background: #242222; */
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #363940;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #fff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #fff;
    transition: 250ms;
  }

  button {
    display: none;
  }
  
}
.btnFacebook { 
  width: 165px;
  height:35px;  
  border-radius: 4px;
  background: #3b5998;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  display: inline-block;
  cursor: pointer;
}
/* Profile sidebar */
.profile-sidebar {
  padding: 20px 0 10px 0;
  /* background: #fff; */
  text-align: center;
  /* margin-top: 30px; */
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 5px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.profile-userbuttons {
  text-align: center;
  margin-top: 5px;
}

.profile-userbuttons .btn {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 15px;
  margin-right: 5px;
}

.profile-userbuttons .btn:last-child {
  margin-right: 0px;
}
    
.profile-usermenu {
  margin-top: 30px;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}

.profile-usermenu ul li a {
  color: #93a3b5;
  font-size: 14px;
  font-weight: 400;
}

.profile-usermenu ul li a i {
  margin-right: 8px;
  font-size: 14px;
}

.profile-usermenu ul li a:hover {
  background-color: #fafcfd;
  color: #5b9bd1;
}

.profile-usermenu ul li.active {
  border-bottom: none;
}

.profile-usermenu ul li.active a {
  color: #5b9bd1;
  background-color: #f6f9fb;
  border-left: 2px solid #5b9bd1;
  margin-left: -2px;
}





.dropdown-menu {
  /* background: red; */
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  z-index: 100;
}

.dropdown-menu li {
  cursor: pointer;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background: #fff;

  
}

.dropdown-menu li:hover {
  color: #818592;
;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #363940 ;
;
  padding: 16px;
}
.dropdown-link:hover{
  color: #818592;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  .error {
    display: block;
    font-size: 15px;
    padding: 10px;
    position: relative;
    min-height: 2.64286em;
    background: #fbefee;
    color: #c23628;
  }
.HyperLink{
  cursor: pointer;
}
label{
  font-weight: 600;

}
.ServeInput{
  width: 40px;
}
.SearchBox{
  width: 50%;
}
li{
  list-style: none;
}

[data-pagination],
[data-pagination] *,
[data-pagination] *:before,
[data-pagination] *:after {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: auto;
  -webkit-font-kerning: auto;
          font-kerning: auto;
}
[data-pagination] {
  font-size: 8pt;
  line-height: 1;
  font-weight: 600;
  font-family: 'Open Sans', 'Source Sans Pro', Roboto, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', 'Myriad Pro', 'Segoe UI', Myriad, Helvetica, 'Lucida Grande', 'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  margin: 1em auto;
  text-align: center;
  transition: font-size .2s ease-in-out;
}
[data-pagination] ul {
  list-style-type: none;
  display: inline;
  font-size: 100%;
  margin: 0;
  padding: .5em;
}
[data-pagination] ul li {
  display: inline-block;
  font-size: 100%;
  width: auto;
  cursor: pointer;
  border-radius: 3px;
}
[data-pagination] ul .active{
  padding: 6px 12px;
    margin: 0 4px;
    border-radius: 3px;
    border: 1px solid #c7c9cf;
    color: #666;
}
[data-pagination] > a {
  font-size: 140%;
}
[data-pagination] a {
  color: #777;
  font-size: 100%;
  padding: .5em;
}
[data-pagination] a:focus,
[data-pagination] a:hover {
  color: #f60;
}
[data-pagination] li.current {
  background: rgba(0,0,0,.1)
}
/* Disabled & Hidden Styles */
[data-pagination] .disabled,
[data-pagination] [hidden],
[data-pagination] [disabled] {
  opacity: .5;
  pointer-events: none;
}
@media (min-width: 350px) {
  [data-pagination] {
    font-size: 8pt;
  }
}
@media (min-width: 500px) {
  [data-pagination] {
    font-size: 12pt;
  }
}
@media (min-width: 700px) {
  [data-pagination] {
    font-size: 14pt;
  }
}
@media (min-width: 900px) {
  [data-pagination] {
    font-size: 16pt;
  }
}


.read-more{
  height: auto;
}
.read-less{
  height: 15px;
  overflow: hidden;
}
.Readmore_text{
  font-weight: 600;
  cursor: pointer;
}
/* nav bar */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}


/* searchbox */

div#search * {
  font-family: sans-serif;
}

div#search {
  align-items: center;
  display: flex;
  max-width: 600px;
  width: 100%;
}

div#search label {
  display: none;
  font-weight: 500;
  margin: 0 5px 0 0;
}

div#search input {
  border: solid #ccc;
  border-width: 1px 0 1px 1px;
  border-radius: 2px 0 0 2px;
  flex: 1 1;
  font-size: 16px;
  font-weight: 200;
  height: 33px;
  margin: 0;
  min-width: 90px;
  padding: 5px;
  -webkit-appearance: textfield;
}

div#search svg {
  background-color: #26882e;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  fill: white;
  height: 33px;
  min-width: 24px;
  padding: 0 7px;
  width: 24px;
}

div#search svg:hover {
  background-color: #1d6823;
  fill: white;
}

@media all and (min-width: 500px) {
  div#search label {
    display: inline;
  }  
  div#search input::-webkit-input-placeholder {
    opacity: 0;
  }  
  div#search input:-ms-input-placeholder {
    opacity: 0;
  }  
  div#search input::-ms-input-placeholder {
    opacity: 0;
  }  
  div#search input::placeholder {
    opacity: 0;
  }  
}
.Anchor{
  cursor: pointer;
}
.ingredientsBtn{
  color: white;
  background-color: #3897f0;
  padding: 10px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-top: 20px!important;
}
.Ingredientcenter{
  text-align: center;
}
/* img {
  display: block;
} */

.thumbnail {
  position: relative;
  display: inline-block;
  background-color: #eff4f7;
  /* background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 65.79%;
    height: 100%; */
}

.caption {
  z-index: 100;
  position: absolute;
  top: 30%;
  right: 10%;
  /* transform: translate( -50%, -50% ); */
  text-align: center;
  color: #363940;
  font-weight: 600;
 
  cursor: pointer;
}
.RecipeTittle{
  font-size: 45px;
}
.sliderimage{
    width: 65.79%;
    height: 500px;
   
}
.slide-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* height: 100%; */
  border-radius: 2px;
  /* color: #9297a4; */
  /* background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyNyUiIHN0b3AtY29sb3I9IiNlZmY0ZjciIHN0b3Atb3BhY2l0eT0iMCIvPgogICAgPHN0b3Agb2Zmc2V0PSI2MyUiIHN0b3AtY29sb3I9IiNlZmY0ZjciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+); */
  /* background: -webkit-gradient(linear,left top,right top,color-stop(27%,#eff4f7),color-stop(63%,rgba(239,244,247,1))); */
  /* background: -webkit-linear-gradient(left,rgba(239,244,247,0) 27%,#eff4f7 63%); */
  background: linear-gradient(to right,rgba(239,244,247,0) 27%,#eff4f7 63%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00eff4f7',endColorstr='#eff4f7',GradientType=1);
}
.slick-next:before, .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #363940;
}
.post_thumb_withbg {
  height: 230px;
  width: 100%;
  color: #fff;
  text-align: center;
  background-color: #eee;
  /* background-repeat: no-repeat; */
  background-position: center center;
  background-size: cover;
  position: relative;
  transition: all .2s ease;
  display: table;
  box-sizing: border-box;
  padding: 10px;
}
.post_thumb{
  color: #fff;
  display: table;
  width: 100%;
  height: 100%;
  border: 1px solid #f1e8e8;
  cursor: pointer;
}
.post_thumb_withbg a:hover{
  border: 2px solid white!important;
}
.ReceipeItem{
  cursor: pointer;
}
.ReceipeItem :hover{
  color: white;
  background-color: #363940;
}
.slide_button {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  margin: 15px 0 0;
}
.slide_button a {
  font-family: "Roboto Condensed",sans-serif;
  display: inline-block;
  color: #fff;
  padding: 10px 30px;
  background: #363940;
  border-radius: 3px;
  transition-duration: .2s;
}
.grandmaslide_button{
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  margin: 15px 0 0;
}
.grandmaslide_button a
{
  font-family: "Roboto Condensed",sans-serif;
    display: inline-block;
    color: #100404;
    padding: 10px 30px;
    background: #fafafa;
    border-radius: 3px;
    transition-duration: .2s;
    cursor: pointer;
}

.CustomSearch{
  padding: 50px 47px;
  background: rgba(0, 0, 0, 0.5);
}

/* @media screen and (max-width:200px) {
  .Ingredientimage img{
    height: 100px!important;
  }
} */

.Ingredientimage img {
  height: 200px!important;
  padding: 5px;
}
.DividerCommon{
  height: 200px;
}
.CustompaddingSlider{
  padding-bottom: 25px!important;
  padding-top: 60px;
}
@media (max-width: 500px){
  /* .Ingredientimage img {
    height: 70px!important;
} */
.LogoStyle{
  font-size: 50px!important;

}
.navbar{
  height: 20px !important;
}
.sliderControl .slider{
  height: 200px!important;
}
.main-heading-secondary {
  line-height: normal!important;
}
.CustompaddingSlider{
  padding-bottom: 0!important;
  padding-top: 0!important;
}
.sliderimage img{
  height: 180px!important;
}
.caption{
  right: 0!important;
  left: 65%!important;
  white-space:nowrap!important
}
}

@media (max-width: 700px){
  .DividerCommon{
    height: auto!important;
  }
}

.LogoStyle{
  font-family: 'Yeon Sung', cursive;
  /* font-family: 'Aclonica', sans-serif; */
/* font-family: 'Annie Use Your Telescope', cursive; */
  text-align: center;
  font-size: 70px;
  color: #363940;
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 0;
}
.Logobelow{
  /* font-family: 'Annie Use Your Telescope', cursive; */
  text-align: center;
  font-size: 10px; 
  margin-top: 10px;
    margin-bottom: 10px;
}
.carousel_item{
  cursor: pointer;
}


/* tree structure */

.item-category-area .single-cat-item {
  text-align: center;
}
.item-category-area .single-cat-item .thumb img {
  border-radius: 90px;
  transition: all 0.3s ease 0s;
}


.with-overlay {
  /*****************************/
  /***** THE MAGIC LINE ********/
  /* background: linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)), url('https://i.imgur.com/JYlHEsM.jpg'); */
  /*****************************/
  background-position: right;
  background-repeat: no-repeat;
}


.div-side::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #dfff12;
  transform: scaleX(0);
  transition: transform ease .3s;
}

.div-side:hover::after {
  transform: scaleX(1);
}
.smooth-offset-box-shadow:hover{
  background-color: crimson;
  transition: all 1s;
  box-shadow: 0 0 5px 5px #4d4646;
}

.main-heading {
  color:white;
  text-transform: uppercase;
}

.main-heading-primary {
  display: block;
  font-weight: 600;
  letter-spacing: 10px;
  -webkit-animation: moveInLeft 5s ease-out;
          animation: moveInLeft 5s ease-out;
}

.main-heading-secondary {
  font-family: sans-serif;
  display: block;
  text-align: center;
  -webkit-animation: moveInRight 1s ease-out;
          animation: moveInRight 1s ease-out;
  color: white;
  font-weight: 400;
}

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  
  80% {
    transform: translateX(10px);
  }
  
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  
  80% {
    transform: translateX(10px);
  }
  
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  
  80% {
    transform: translateX(-10px);
  }
  
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  
  80% {
    transform: translateX(-10px);
  }
  
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.hero {
	background-color: #000000;
	background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/static/media/Grandmasays2.3d44d2e3.jpg);
  background-position: center;
	background-size: cover;
	color: #ffffff;
	padding: 1em;
	text-align: center;
}
.CommonColor{
  color: #ff7e05!important;
}
logo-slider {
  --image-size: 100px;
  /* border: 1px solid #575757; */
  padding: 20px;
  overflow: hidden;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  cursor: pointer;
}
logo-slider:hover div {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
logo-slider div {
  display: flex;
  position: relative;
  -webkit-animation: marquee 20s linear infinite;
          animation: marquee 20s linear infinite;
  justify-content: space-around;
}
logo-slider img {
  display: block;
  min-width: var(--image-size);
  height: var(--image-size);
  margin: 0 1vw;
}
logo-slider:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0) 80px, rgba(255, 255, 255, 0) calc(100% - 80px), white);
}
@media (max-width: 900px) {
  logo-slider {
    --image-size: 100px;
    --image-size: min(max(100px, 10vw), 100px);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.centeredText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
.Slidecontainer{
  position: relative;
  text-align: center;
  color: white;
}
/* Recipe page */
.NewcontainerDesign .segments{
border:none!important;
box-shadow:none!important
}

.NewcontainerDesign .segments>.segment{
  border:none!important;
box-shadow:none!important;
padding-bottom: 0!important;
}
.StepPadding{
  padding: 0!important;
}
.CommonBackground{
  background: #ff7e05b8!important;
}

