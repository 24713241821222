@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Annie+Use+Your+Telescope&family=Capriola&family=Yeon+Sung&display=swap');
body {
  margin: 0;
 font-family: 'Merriweather', serif!important;

 
}

main{
  background: #fafafa;
}
@media only screen and (max-width: 600px) {
  .RecipeTittle {
    font-size: 18px!important;
  }
  .slide_button a{
    padding: 5px 10px!important;
    font-size: 12px!important;
    margin: 0px 0 0!important;
  }
 
}
@media (max-width: 1025px) {
  .sliderimage {
    height:auto!important;
  }
}
.slick-dots li button:before{
  font-size: 17px!important;
}
.ui.label{
  /* color: #feaa26!important; */
  background-color: transparent!important;
}
.ui.multiple.search.dropdown:hover{
  border: 1px solid #363940;
}
.coolHead {
  text-align: center;
  font-family: 'Oswald', Helvetica, sans-serif;
  font-size: 80px;
  transform: skewY(-10deg);
  letter-spacing: 4px;
  word-spacing: -8px;
  color: tomato;
  text-shadow: 
    -1px -1px 0 firebrick,
    -2px -2px 0 firebrick,
    -3px -3px 0 firebrick,
    -4px -4px 0 firebrick,
    -5px -5px 0 firebrick,
    -6px -6px 0 firebrick,
    -7px -7px 0 firebrick,
    -8px -8px 0 firebrick,
    -30px 20px 40px dimgrey
}
.breadcrumb{
  font-size:15px!important;
  font-weight:600 ;
}





