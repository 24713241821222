.dropdown-menu {
  /* background: red; */
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  z-index: 100;
}

.dropdown-menu li {
  cursor: pointer;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background: #fff;

  
}

.dropdown-menu li:hover {
  color: #818592;
;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #363940 ;
;
  padding: 16px;
}
.dropdown-link:hover{
  color: #818592;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
